import './redirecting.scss';

export default function Redirecting() {
	return (
		<>
			<p class='redirecting'>
				Redirection en cours<span>.</span>
				<span>.</span>
				<span>.</span>
			</p>
		</>
	);
}
