import './gallery.scss';
import React, { useState, useEffect } from 'react';
import Card from '../card/card';
import accommodationData from '../../data/accommodation.json';

const Gallery = () => {
	const [accommodations, setAccommodations] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(false);

	useEffect(() => {
		let isMounted = true;

		const fetchData = async () => {
			try {
				const data = await accommodationData;

				if (isMounted) {
					setAccommodations(data);
					setIsLoading(false);
				}
			} catch (error) {
				console.error(`Fetch error: ${error.message}`); // log the error for debugging
				if (isMounted) {
					setError(true);
					setIsLoading(false);
				}
			}
		};

		fetchData();

		return () => {
			isMounted = false;
		};
	}, []);

	if (isLoading) {
		return <div>Loading...</div>;
	}

	if (error) {
		return <h1>Please run the server on port 3000.</h1>;
	}

	return (
		<div className='gallery'>
			{accommodations.map((accommodation) => (
				<Card key={accommodation.id} {...accommodation} />
			))}
		</div>
	);
};

export default Gallery;
