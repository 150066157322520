import './about.scss';
import { useState, useEffect } from 'react';
import Banner from '../../components/banner/banner';
import Collapse from '../../components/collapse/collapse';
import aboutData from '../../data/about.json';

export default function About() {
	const [aboutDatas, setAboutDatas] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await aboutData;
				setAboutDatas(data);
				setIsLoading(false);
			} catch (error) {
				setError(error.message);
				setIsLoading(false);
			}
		};

		fetchData();
	}, []);

	if (isLoading) {
		return <div>Loading...</div>;
	}

	if (error) {
		return <h1>Please run the server on port 3000.</h1>;
	}

	return (
		<main className='about main-pages'>
			<Banner />
			<div className='about-content'>
				{aboutDatas.map((data) => (
					<Collapse key={data.id} title={data.title} content={data.content} />
				))}
			</div>
		</main>
	);
}
